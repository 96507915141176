<template>
  <div class="container-xxl header">
    <h2 class="text-primary">{{ $t('advantages.title') }}</h2>
    <h6>{{ $t('advantages.intro') }}</h6>
  </div>
  <TextImage  v-for="(category, index) in categories" :key="index" :cat="category" :pos="index%2" />
  <Footer />
</template>

<script>
import _ from 'lodash';
import TextImage from '@/components/TextImage'
import Footer from '@/components/Footer.vue'
import cats from '@/locales/de.json'

export default {
  name: 'Advantages',
  components: {
    Footer,
    TextImage
  },
  data() {
    return {
      categories: _.filter(Object.keys(cats.advantages), function(o) { return o!='title' && o!='intro'; })
    };
  }
}
</script>

<style scoped>
  .container-xxl {
    margin-bottom: 50px;
  }

  .header{
    padding-top: 50px;
  }
</style>
